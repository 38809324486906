const projects = [
  {
    title: "Typographia Typing Challenge",
    imageUrl: "/images/project-cards/typographia.png",
    link: "/typographia",
  },
  {
    title: "Settlers of Catan Board Generator",
    imageUrl: "/images/project-cards/catan-board.png",
    link: "/catan",
  },
];

export default projects;
